@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.dh-container {
    margin-top: $space-24;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: unset;
        font-family: $font-questa;
        font-size: $font-36;
        font-weight: $font-black;
    }

    p {
        font-family: $font-sofia;
        font-size: $font-18;
        font-weight: $font-medium;
        margin: $space-24 0;
    }

    &.italic {
        em {
            font-style: normal;
        }

        &-color-red {
            em {
                color: $color-e300;
            }
        }

        &-color-paprika {
            em {
                color: #ff595a;
            }
        }

        &-color-merlot {
            em {
                color: $color-s500;
            }
        }

        &-color-ship-lap {
            em {
                color: #f6f4f1;
            }
        }

        &-color-new-adirondack {
            em {
                color: $color-p300;
            }
        }

        &-color-blue-melamine {
            em {
                color: $color-b300;
            }
        }

        &-color-saffron {
            em {
                color: #f6c862;
            }
        }

        &-color-black {
            em {
                color: #000;
            }
        }

        &-color-white {
            em {
                color: $color-n0;
            }
        }

        &-color-primary-green {
            em {
                color: $color-p500;
            }
        }
    }
}

.dh-link {
    letter-spacing: 0.8px;
}
